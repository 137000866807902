import React from 'react';
import { NextPageContent } from '../../types';

type Props = {
    content: NextPageContent;
};

const ModuleHead: React.FC<Props> = ({ content }) => {
    if (!content.headline) return null;

    return (
        <React.Fragment>
            <h2 dangerouslySetInnerHTML={{ __html: content.headline }} />
            <div style={{ clear: 'both' }} />
        </React.Fragment>
    );
};

export default ModuleHead;
